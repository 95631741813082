import React from 'react';
import { Grid, Row, Col } from '@r007/react-styled-flexboxgrid';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Segmented from '../components/Segmented';
import Layout from '../layouts/Layout';
import Heading from '../components/Heading';
import NewsletterForm from '../components/NewsletterForm';
import ContactUs from '../components/ContactUs';
import { Spacing } from '../components/common';
import electronicstechnician from './files/ElectronicsTechnician.pdf';
import ITnetworkengineer from './files/ITnetworkandsystemsengineer.pdf';
import seniorsoftwareengineer from './files/seniorsoftwareengineer.pdf';
import USVsupporttech from './files/USVsupporttech.pdf';
import AutonomyEngineer from './files/Autonomysoftwareengineer.pdf';

const Accent = styled.span`
  color: #4ab4e6;
  font-weight: 700;
`;

const CenteredCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function CareersPage() {
  return (
    <Layout>
      <SEO
        title="Careers"
        description="Working with Ocius &#8211; &#8220;Oceans is us&#8221; Ocius is an Australian public unlisted
      Sydney-based SME (small to medium enterprise) operating at the cutting edge of technology to design and
      build autonomous robots."
      />
      <section className="page-content">
        <Grid>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Spacing $value="80px" />
              <Heading level={1} size="huge" weight="thick" underline="left">
                Careers
              </Heading>
            </Col>
          </Row>
          <Spacing $value="20px" $xsValue="0" />

          <Segmented multiplier={2} borderBottom>
            <Row>
              <CenteredCol xs={12} md={8} lg={8}>
                <Heading level={2} size="medium" weight="thick">
                  About Us.
                </Heading>
                <p>
            Ocius is unlike any company you've worked for before. An unlisted Australian-owned company, we design, manufacture, and deploy high-quality, technologically advanced autonomous vessels for customers across various sectors. Our mission is to protect our borders, preserve our maritime environment, and enhance Australia's social impact and capabilities.
                </p>
<Heading level={2} size="medium" weight="thick">
                  Who are we.
                </Heading>
                <p>
                  We are a bunch of innovative thought leaders with diverse capabilities, experiences and ideas. We bring this diversity to the table to design, ideate, test, launch and continuously improve our offering. 
From Engineers, to Software Designers, Data Scientists, our team comprises the best of breed. 
                </p>
<Heading level={2} size="medium" weight="thick">
                  What it takes to work here.
                </Heading>
                <p>
                  We are a fast paced, high performing team. We are resilient. We are agile. We work together to deliver results. Every time. We are open minded, and learn everyday. We roll up our sleeves to get the job done.
                </p>
<Heading level={2} size="medium" weight="thick">
                  Ready to grow your career?
                </Heading>
                <p>
                 We don't recruit for roles. We recruit for our future organisation. 
Regardless where you are in your career journey from graduate, through to senior executive, we welcome interest from people of all backgrounds and capabilities.
                </p>
                <p> We provide mentorship, on the job training, coaching and hands on experience in a research and development environment unlike any other. Are you ready to be part of something big?
                </p>
                    <p>
Send your resume to <a href="mailto:josette.moffatt@ocius.com.au">josette.moffatt@ocius.com.au</a>, or contact Josette Moffatt for a confidential discussion 0455 302 300
</p>
<Heading level={1} size="huge" weight="thick" underline="left">
                Vacant Roles
              </Heading>
<Heading level={2} size="medium" weight="thick">
                 Electronics Technician
                </Heading>
<p>
                 The Engineering and Production function is responsible for the Design, Manufacture, Assembly of Ocius assets, namely
the Bluebottle.
The function is accountable for, Designing the product focusing on Research and Development and Innovation,
Production, Governance and Compliance focusing on delivery of standard operating procedures, safety and project
reporting, and supporting maintenance of the assets.
                </p>
<p>
		The role is responsible for the assembly, maintenance, and general workshop activities.
</p>
<p>
		For more information:  <a href={electronicstechnician} >ElectronicsTechnician.pdf</a>
</p>
<Heading level={2} size="medium" weight="thick">
                 IT Network and Systems Engineer
                </Heading>
<p>
                 The role of the Network and Systems Engineer works to support a multidisciplinary engineering team in the delivery of a
complex ICT-based Systems. The role will design, implement and maintain systems and services. This role requires a deep
understanding of network infrastructure, security protocols, and the ability to manage and optimize complex IT
environments.
The team is responsible for maintaining network hardware and software, monitoring network performance, and
troubleshooting issues to ensure minimal downtime.
                </p>
<p>
		For more information:  <a href={ITnetworkengineer} >ITnetworkandsystemsengineer.pdf</a>
</p>
<Heading level={2} size="medium" weight="thick">
                 Senior Software Engineer
                </Heading>
<p>
                As a result of growth, we have opportunity for a senior software engineer to work in a team to build software architecture,
monitor and test software and upgrade existing systems. The role will develop software solutions and handle the
development and maintenance of software applications.
The team is responsible for providing systems support to ensure issued are identified and delt with, software
configuration and ensuring SOPS are developed and adhered to.
                </p>
<p>
		For more information:  <a href={seniorsoftwareengineer} >seniorsoftwareengineer.pdf</a>
</p>
<Heading level={2} size="medium" weight="thick">
                 USV Support Technician
                </Heading>
<p>
                The role of the Support Technician is to play a crucial role in ensuring the smooth operation of software systems. The role
works in a team to provide end-user support, troubleshoot hardware and software issues, and even develop plans for
software maintenance and configuration management.
The team is responsible for collaborating to design, build, and maintain software applications. These team members
work in unison, bringing together diverse talents and expertise to achieve a common goal: the development of highquality
software for the delivery of world class autonomous vessels.
                </p>
<p>
		For more information:  <a href={USVsupporttech} >USVsupporttech.pdf</a>
</p>
<Heading level={2} size="medium" weight="thick">
                 Autonomy Software Engineer
                </Heading>
<p>
                As a result of growth, we have an opportunity for an autonomy software engineer to develop the autonomous
capabilities of our USV platforms. This includes behaviours such as sailing, collision avoidance, teaming, surveillance,
mapping, emergency response and fault tolerance. These will be used for real-world tasks.
                </p>
<p>
		For more information:  <a href={AutonomyEngineer} >Autonomysoftwareengineer.pdf</a>
</p>
              </CenteredCol>
            </Row>
          </Segmented>
        </Grid>

        <Heading className="centered" level={3} size="small" weight="thick" color="#4ab4e6">
          For further details please contact{' '}
          <a href="mailto:josette.moffatt@ocius.com.au">josette.moffatt@ocius.com.au</a>.
        </Heading>

        <Segmented>
          <NewsletterForm />
        </Segmented>
        <Segmented>
          <ContactUs />
        </Segmented>
      </section>
    </Layout>
  );
}

export default CareersPage;
